var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product-detail-page"},[_c('vue-breadcrumb',{attrs:{"page-name":_vm.categoryObj.name,"shop-name":_vm.productObj.name}}),_c('section',{staticClass:"section-sm"},[_c('div',{staticClass:"container container-lg container-md "},[_c('div',{staticClass:"row row-50"},[_c('div',{staticClass:"col-md-6 col-lg-5"},[(_vm.skuPicture.length > 0)?_c('vue-images',{attrs:{"skuPicture":_vm.skuPicture,"pics_M":_vm.pics_M,"pics_S":_vm.pics_S}}):_vm._e()],1),_c('div',{staticClass:"col-md-6 col-lg-7"},[_c('div',{staticClass:"product-single"},[_c('h2',[_vm._v(_vm._s(_vm.productObj.name))]),_c('h6',{staticClass:"single-en-title"},[_vm._v(_vm._s(_vm.productObj.summary))]),_c('div',{staticClass:"product-price big"},[_c('span',{staticClass:"money-wrap"},[_c('span',{staticClass:"money-flag"},[_vm._v("¥ ")]),_c('span',{staticClass:"money-num"},[_vm._v(" "+_vm._s(_vm.priceYuan)),_c('span',{staticClass:"price-decimals"},[_vm._v("."+_vm._s(_vm.priceCent))])])])]),_c('vue-star',{attrs:{"score":_vm.productObj.score}}),_c('div',{staticClass:"product-select"},_vm._l((_vm.attrs),function(attr,index){return _c('div',{key:index,staticClass:"sku-items flex items-center"},[_c('label',[_vm._v("选择"+_vm._s(attr.name))]),_c('div',{staticClass:"items-wrap"},_vm._l((attr.values),function(value,ind){return _c('div',{key:ind,class:[
                      _vm.selectedAttrs.find(function (_) { return _.name == attr.name; }).value === value
                        ? 'selected'
                        : '',
                      'item'
                    ],attrs:{"value":value},on:{"click":function($event){return _vm.updateSku(attr.name, value)}}},[_vm._v(" "+_vm._s(value)+" ")])}),0)])}),0),_c('div',{staticClass:"step-wrap text-center"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.productNum),expression:"productNum"}],staticClass:"form-control",attrs:{"type":"number","min":"1","value":"1"},domProps:{"value":(_vm.productNum)},on:{"input":function($event){if($event.target.composing){ return; }_vm.productNum=$event.target.value}}}),_c('span',{staticClass:"stepper-arrow up bi-chevron-up",on:{"click":_vm.addCartCount}}),_c('span',{staticClass:"stepper-arrow down bi-chevron-down",on:{"click":_vm.reduceCartCount}})]),_c('div',{staticClass:"button button-primary",on:{"click":_vm.toggleCart}},[_vm._m(0)]),_c('div',{class:[
                'product-icon-wrap',
                _vm.$store.state.wishlist.indexOf(_vm.skuId) > -1 ? 'isFavorite' : ''
              ],on:{"click":function($event){return _vm.toggleWishlistHandler()}}},[_c('span',{staticClass:"bi-heart"})]),_c('ul',{staticClass:"cate-tag-ul"},[_c('li',[_c('label',[_vm._v("分类：")]),_c('div',{staticClass:"cursor",on:{"click":function($event){return _vm.goProductListHandler('0')}}},[_vm._v(" "+_vm._s(_vm.shopCategoryName)+" ")])]),_c('li',[_c('label',[_vm._v("标签：")]),_c('div',{staticClass:"product-categories"},_vm._l((_vm.productObj.tags),function(_){return _c('span',{key:_.tagId,on:{"click":function($event){return _vm.goProductListHandler(_.tagId)}}},[_vm._v(" "+_vm._s(_.name)+" ")])}),0)])])],1)])]),_c('div',{staticClass:"mt50"},[_c('h4',{staticClass:"text-left"},[_vm._v("产品介绍")]),_c('div',{staticClass:"information-introduction text-left",domProps:{"innerHTML":_vm._s(_vm.productObj.description)}})])]),_c('vue-related-products',{attrs:{"textOffset":"text-left","productId":Number(_vm.productId)}}),_c('div',{staticClass:"container container-lg container-md "},[_c('vue-comment',{attrs:{"articleId":_vm.productId}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"bi-cart2"},[_c('span',[_vm._v("添加到购物车")])])}]

export { render, staticRenderFns }